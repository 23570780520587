//
//
//
//
//
//

// @ is an alias to /src

export default {
  name: 'Nous'
}
